import React from "react";
import { Screen } from "../screen/Screen";
import { List } from "../list/List";
import {startTyping} from "../info/thunks";
import {useAppDispatch} from "../../app/hooks";
import wishlist from './wishlist.json';

export const Wishlist = () => {
  const dispatch = useAppDispatch();

  return (
    <Screen>
      <h1>Список желемого</h1>
      <List
        list={wishlist.map((item) => `<a target="_blank" ${item.link ? `href="${item.link}` : ''}">${item.name}</a>`)}
        onTypingStart={() => dispatch(startTyping())}
      />
    </Screen>
  )
}
